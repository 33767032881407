const styleString = '.cls-1{fill:#fff;}';

const RailgunIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 407.29 50.5"
  >
    <defs>
      <style>{styleString}</style>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          className="cls-1"
          d="M94.33,39H75.5L71.67,50.08h-8L81.78.42h6.28l18.06,49.66h-8Zm-2.16-6.7L85,10.6h-.14L77.66,32.29Z"
        />
        <path className="cls-1" d="M125.05.42h7.54V50.08h-7.54Z" />
        <path className="cls-1" d="M154.84.42h7.53V43h24.2v7.12H154.84Z" />
        <path
          className="cls-1"
          d="M259.74.42h7.54v32c0,6.84,4.39,10.53,10.11,10.53s10.11-3.69,10.11-10.53V.42H295V33.27c0,10.32-7.81,17.23-17.65,17.23s-17.65-6.91-17.65-17.23Z"
        />
        <path
          className="cls-1"
          d="M316.87.42H324l22.46,35.29h.13V.42h7.54V50.08H347L324.54,14.79h-.14V50.08h-7.53Z"
        />
        <path
          className="cls-1"
          d="M231.44,14.72h8C237.86,6.63,231.38,0,222,0a17.63,17.63,0,0,0-15.55,8.93c-1.22,2.22-1.84,3.61-2.06,9.37,0,0-.11,5.05-.11,7,0,1.64,0,3.08,0,4.35l0,19.8H212V17.27c.32-3.33,1-4.49,2.26-6.11A9.55,9.55,0,0,1,222,7.53,10,10,0,0,1,231.44,14.72Z"
        />
        <polygon
          className="cls-1"
          points="222.03 22.74 222.03 29.85 231.88 29.85 231.88 49.4 239.64 49.4 239.68 22.74 222.03 22.74"
        />
        <path className="cls-1" d="M372.41,46.59h34.88v3.49H372.41Z" />
        <image
          width="31"
          height="8"
          transform="translate(-0.97 6.5)"
          xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB8AAAAICAYAAAABfSQaAAAACXBIWXMAAAsSAAALEgHS3X78AAAAHklEQVQoU2P8////f4YBAkyEFNASjFo+IGDU8gEBAKpzBAxnkQL4AAAAAElFTkSuQmCC"
        />
        <path
          className="cls-1"
          d="M35.19,28A14.08,14.08,0,0,0,44.82,14.3a14,14,0,0,0-5-10.6C37.21,1.4,33.94.42,29.75.42H10.36V7.11H29.68a8.34,8.34,0,0,1,5.51,1.54A6.69,6.69,0,0,1,37.7,14.3c0,4.18-2.79,7.88-8.44,7.88H10.36v27.9h7.53V28.88h9.63l10.25,21.2H46.7Z"
        />
      </g>
    </g>
  </svg>
);

export default RailgunIcon;
