import { Link } from '@mui/icons-material';
import Button from '@mui/material/Button';

export const GovernanceButton = ({ sx }) => (
  <Button
    variant="outlined"
    href="https://governance-railgun.on.fleek.co/"
    target="_blank"
    rel="noreferrer noopener"
    sx={sx}
    size="small"
  >
    <Link />
    Governance
  </Button>
);

export const AppButton = ({ sx }) => (
  <Button
    variant="outlined"
    href="https://railway.xyz/"
    target="_blank"
    rel="noreferrer noopener"
    sx={sx}
    size="small"
  >
    <Link />
    Launch Wallet
  </Button>
);

export const DocsButton = ({ sx }) => (
  <Button
    variant="outlined"
    href="https://docs.railgun.org"
    target="_blank"
    rel="noreferrer noopener"
    sx={sx}
    size="small"
  >
    <Link />
    Docs 
  </Button>
);

export const LegacyButton = ({ sx }) => (
  <Button
    variant="outlined"
    href="https://legacy-railgun.on.fleek.co/"
    target="_blank"
    rel="noreferrer noopener"
    sx={sx}
    size="small"
  >
    <Link />
    Legacy App
  </Button>
);
