import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

export const CommunityLinkButton = ({ icon, text, subtext, ...props }) => (
  <Button
    variant="outlined"
    aria-label="text"
    sx={{
      minWidth: '237px',
      '& .MuiSvgIcon-root': { height: '3.5em', width: '3.5em' },
    }}
    {...props}
  >
    {icon}
    <Box sx={{ ml: 1 }}>
      <Typography>{text}</Typography>
      <Typography>{subtext}</Typography>
    </Box>
  </Button>
);
