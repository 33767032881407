import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import { CommunityLinkButton } from '../../components/communityLinkButton';

import { communityContent } from '../../content/communityContent';

const Community = ({ communityRef }) => (
  <Container ref={communityRef} maxWidth="lg" sx={{ mt: 5 }}>
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        flexBasis: 'fit-content',
        rowGap: 3,
        columnGap: 3,
      }}
    >
      {communityContent.map((content, index) => (
        <CommunityLinkButton key={index} {...content} />
      ))}
    </Box>
  </Container>
);

export default Community;
