import { createTheme } from '@mui/material/styles';

import DINPro from './assets/font/DINPro.woff';

export const theme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#141414',
    },
    secondary: {
      main: '#3f3e3d',
    },
    background: {
      default: '#151517',
      paper: '#1e1e21',
    },
  },
  typography: {
    fontFamily: 'DIN Pro',
    color: '#fff',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'DIN Pro';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('DIN Pro'), url(${DINPro}) format('woff');
        }
      `,
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          MuiAccordion: {
            styleOverrides: {
              root: {
                backgroundColor: '#fff',
              },
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: '#fff',
          '&:hover': {
            backgroundColor: '#ced3df0a',
          },
        },
        outlined: {
          border: '1px solid #393939',
          '&:hover': {
            border: '1px solid #887e72',
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: '#fff',
          '&:hover': {
            backgroundColor: '#ced3df0a',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#fff',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#fff',
          border: '1px solid #393939',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#2B7FFF',
          '&:hover': { color: 'lightblue' },
        },
      },
    },
  },
});

export default theme;
