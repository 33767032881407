import Container from '@mui/material/Container';

import { RailgunIconSplash } from '../../components/railgunIconSplash';
import { TrustLinks } from '../../components/trustLinks';

const Splash = ({ homeRef }) => (
  <Container
    ref={homeRef}
    sx={{
      minHeight: 'calc(100vh - 64px)',
    }}
  >
    <RailgunIconSplash />
    <TrustLinks />
  </Container>
);

export default Splash;
