import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import { rows, columns } from '../../content/bugBountyTableData';
import bugBountyContent from '../../content/bugBountyContent';

function currencyColor(price) {
  switch (price) {
    case 100:
      return '#196F3D';
    case 500:
      return '#27AE60';
    case 1000:
      return '#82E0AA';
    case 5000:
      return '#F9E79F ';
    case 10000:
      return '#F4D03F ';
    case 50000:
      return '#F5B041';
    case 250000:
      return '#E74C3C ';
    default:
      return '#fff';
  }
}

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 0,
});

function renderBodyContent(title, body) {
  if (Array.isArray(body)) {
    return (
      <Box component="ul">
        {body.map((entry, index) => (
          <Typography key={title + index} component="li">
            {entry}
          </Typography>
        ))}
      </Box>
    );
  }

  return body;
}

export default function BugBounty({ bugBountyRef }) {
  return (
    <Container ref={bugBountyRef} maxWidth="lg" sx={{ mt: 2 }}>
      <Box>
        <Paper sx={{ padding: 3 }}>
          <Typography variant="h4" gutterBottom>
            {bugBountyContent.title}
          </Typography>

          <Box sx={{ pb: 2 }}>{bugBountyContent.subtitle}</Box>

          {bugBountyContent.content.map((entry, entryIndex) => {
            return (
              <Box key={`entry-${entryIndex}`} sx={{ pb: 2 }}>
                <Typography variant="h6" gutterBottom>
                  {entry.title}
                </Typography>
                {renderBodyContent(entry.title, entry.body)}
              </Box>
            );
          })}

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography
              sx={{
                writingMode: 'vertical-rl',
                fontSize: 15,
              }}
            >
              Likelihood
            </Typography>
            <TableContainer sx={{ padding: 3 }}>
              <Table size="small" aria-label="bug bounty table">
                <TableHead>
                  <TableRow>
                    {columns.map(({ label, sx = {} }) => (
                      <TableCell key={label + sx} sx={sx}>
                        {label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row, index) => (
                    <TableRow key={index}>
                      {row.map((cell, cellIndex) => {
                        const value =
                          typeof cell === 'number'
                            ? currencyFormatter.format(cell)
                            : cell;
                        return (
                          <TableCell
                            key={`cell-${cell}-${cellIndex}`}
                            sx={{ color: currencyColor(cell) }}
                          >
                            {value}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <Typography
                sx={{
                  width: '100%',
                  justifyContent: 'center',
                  display: 'flex',
                  mt: 2,
                  fontSize: 15,
                }}
              >
                Severity
              </Typography>
            </TableContainer>
          </Box>
        </Paper>
      </Box>
    </Container>
  );
}
