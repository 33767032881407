export const columns = [
  { label: '', sx: { width: '150px' } },
  { label: 'Very low' },
  { label: 'Low' },
  { label: 'Moderate' },
  { label: 'High' },
  { label: 'Severe' },
];
export const rows = [
  ['Almost certain', 1000, 5000, 10000, 50000, 250000],
  ['Likely', 500, 1000, 5000, 10000, 50000],
  ['Possible', 500, 500, 1000, 5000, 10000],
  ['Unlikely', 500, 500, 500, 1000, 5000],
  ['Almost impossible', 500, 500, 500, 500, 1000],
];
