import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const bugBountyContent = {
  title: 'Bug Bounty',
  subtitle: (
    <Typography>
      {
        'Donors who are supporting the RAILGUN Project will pay for responsibly disclosed bugs and exploits for anyone who reports to '
      }
      <Link href="railgun_disclosure@proton.me">railgun_disclosure@proton.me</Link>
    </Typography>
  ),
  content: [
    {
      title: 'Rules:',
      body: [
        'Public disclosure of a vulnerability would make it ineligible for a reward.',
        'Interference with or exploitation of the protocol with a vulnerability would make it ineligible for a reward. It is recommended to use fork-mode for testing or demonstrating a vulnerability.',
        'Duplicate issues are not eligible for reward.',
        'Rewards will vary depending on the severity of the issue. Other variables considered for rewards include: the quality of the issue description, the instructions for reproducibility, and the quality of the fix (if included).',
        'The severity of the issues will be based according to the OWASP risk rating model based on Impact and Likelihood.',
        'Submissions needs to be related with the Bounty Scope. Submissions out of the Bounty Scope won’t be eligible for a reward.',
        'Rewards will be decided on a case by case basis and the bug bounty program, terms, and conditions are at the sole discretion of donors. ',
      ],
    },
    {
      title: 'Scope:',
      body: [
        <Typography>
          {'Deployed or candidate deployment contracts at '}
          <Link
            href="https://www.github.com/Railgun-Privacy/contract"
            target="_blank"
            rel="noreferrer noopener"
          >
            github.com/Railgun-Privacy/contract
          </Link>
        </Typography>,
        <Typography>
          {'Deployed or candidate deployment contracts at '}
          <Link
            href="https://www.github.com/Railgun-Improvements"
            target="_blank"
            rel="noreferrer noopener"
          >
            github.com/Railgun-Improvements
          </Link>
        </Typography>,
      ],
    },
    {
      title: 'Rewards:',
      body: (
        <Typography>
          The reward will be based on likelihood and severity of the exploit up
          to the following amounts:
        </Typography>
      ),
    },
  ],
};

export default bugBountyContent;
