import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import useGetMediumArticles from '../../hooks/useGetMediumArticles';
import MediumArticle from '../../components/mediumArticle';

const Articles = ({ articlesRef }) => {
  const articles = useGetMediumArticles({ numberOfArticles: 6 });
  return (
    <Container ref={articlesRef} maxWidth="lg" sx={{ mt: 5 }}>
      <Typography sx={{ textAlign: 'center', mt: 5 }} variant="h3" gutterBottom>
        Articles
      </Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          flexBasis: 'fit-content',
          rowGap: 3,
          columnGap: 3,
        }}
      >
        {articles.map((article, index) => (
          <MediumArticle key={index} {...article} />
        ))}
      </Box>
    </Container>
  );
};

export default Articles;
