import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import Articles from './articles';

const Press = ({ navigationRefs }) => {
  return (
    <Container>
      <Box
        sx={{
          minHeight: 'calc(100vh - 64px)',
          pt: '64px',
        }}
      >
        <Articles articlesRef={navigationRefs.articles} />
      </Box>
    </Container>
  );
};

export default Press;
