import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { TrustLinksContent as links } from '../content/linksContent';

export const TrustLinks = () => {
  return (
    <Grid
      container
      spacing={{ xs: 1, md: 3 }}
      columns={{ xs: 4, sm: 8, md: 12 }}
      justifyContent="center"
    >
      {links.map(({ href, text }, index) => (
        // index <= linkSplitIndex && (
        <Grid item xs={2} sm={4} md={4} key={index}>
          <Button
            variant="outlined"
            href={href}
            target="_blank"
            rel="noreferrer noopener"
            sx={{ minWidth: '100%' }}
            key={text}
          >
            {text}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};
