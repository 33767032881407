import GitHubIcon from '@mui/icons-material/GitHub';
import TelegramIcon from '@mui/icons-material/Telegram';
import TwitterIcon from '@mui/icons-material/Twitter';

export const communityContent = [
  {
    href: 'https://t.me/railgunproject',
    icon: <TelegramIcon fontSize="inherit" />,
    text: 'Announcements',
    subtext: '@railgunproject',
    target: '_blank',
    rel: 'noreferrer noopener',
  },
  {
    href: 'https://t.me/railgun_privacy',
    icon: <TelegramIcon fontSize="inherit" />,
    text: 'Chat',
    subtext: '@railgun_privacy',
    target: '_blank',
    rel: 'noreferrer noopener',
  },
  {
    href: 'https://twitter.com/railgun_project',
    icon: <TwitterIcon fontSize="inherit" />,
    text: 'Twitter',
    subtext: '@railgun_project',
    target: '_blank',
    rel: 'noreferrer noopener',
  },
  {
    href: 'https://github.com/railgun-privacy',
    icon: <GitHubIcon fontSize="inherit" />,
    text: 'Github',
    subtext: 'railgun-privacy',
    target: '_blank',
    rel: 'noreferrer noopener',
  },
];
