import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Stack from '@mui/material/Stack';

import { GovernanceButton, AppButton, DocsButton } from './appButtons';

export const NavDrawer = ({ isDrawerOpen, setIsDrawerOpen, anchors }) => {
  const closeDrawer = () => setIsDrawerOpen(false);

  return (
    <Drawer anchor={'left'} open={isDrawerOpen} onClose={closeDrawer}>
      <Box
        sx={{
          width: '250px',
          height: '100vh',
          bgcolor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
        role="presentation"
      >
        <Container>
          <List>
            {anchors.map(({ onClick, text }) => (
              <ListItemButton
                component="a"
                key={text}
                onClick={onClick}
                sx={{
                  pl: 3,
                }}
              >
                <ListItemText primary={text} />
              </ListItemButton>
            ))}
          </List>
        </Container>

        <Stack spacing={2} sx={{ pl: 3, pr: 3, mb: 5 }}>
          <AppButton />
          <GovernanceButton />
          <DocsButton />
        </Stack>
      </Box>
    </Drawer>
  );
};
