import parse from 'html-react-parser';
import moment from 'moment';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

import RailgunIcon from '../assets/favicon.png';

const MediumTypographySx = {
  fontFamily: 'charter, Georgia, Cambria, "Times New Roman", Times, serif',
  mt: '1.56em',
  textRendering: 'optimizeLegibility',
  color: 'rgba(0,0,0,0.8)',
  fontStyle: 'normal',
  fontWeight: 400,
  overflowWrap: 'break-word',
  wordBreak: 'break-word',
};

const MediumArticle = ({ title, pubDate, guid, author, description }) => {
  const now = moment();
  const publicationDate = moment(pubDate);
  const calculatedDaysSincePubDate = now.diff(publicationDate, 'days');

  const getPublicationDateText = () => {
    if (calculatedDaysSincePubDate === 0) {
      return `today`;
    }
    if (calculatedDaysSincePubDate === 1) {
      return '1 day ago';
    }
    return `${calculatedDaysSincePubDate} days ago`;
  };

  const subtitle = `${author} ${getPublicationDateText()}`;

  return (
    <Card
      sx={{
        maxWidth: 320,
        maxHeight: 692,
        backgroundColor: '#fff',
        '& figure': { margin: 0 },
        '& img': { width: '100%' },
      }}
    >
      <CardActionArea
        sx={{ backgroundColor: '#fff' }}
        href={guid}
        target="_blank"
        rel="noreferrer noopener"
      >
        <CardContent
          sx={{ backgroundColor: '#fff', padding: 0, margin: '0 24px' }}
        >
          <Typography
            sx={{
              ...MediumTypographySx,
              fontSize: '32px',
              lineHeight: '40px',
              letterSpacing: '-0.512px',
              mt: 0,
              pt: '1.56em',
            }}
            variant="h5"
            component="div"
          >
            {title}
          </Typography>

          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: '32px',
              '& p': { margin: 0 },
            }}
          >
            <Avatar
              sx={{ width: '28px', height: '28px', mr: '8px' }}
              src={RailgunIcon}
              alt={author}
            />
            <Typography
              sx={{
                ...MediumTypographySx,
                color: 'rgb(132, 133, 133)',
                fontSize: '14px',
                lineHeight: '20px',
              }}
              color="text.secondary"
            >
              {subtitle}
            </Typography>
          </Box>

          <Typography
            sx={{
              ...MediumTypographySx,
              fontSize: '18px',
              lineHeight: '28px',
              letterSpacing: '-0.054px',
            }}
            variant="body2"
          >
            {parse(description)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default MediumArticle;
