import { useState, useEffect } from 'react';
import medium from '@giuseppecampanelli/medium-api';

const railgunProfile = 'Railgun_Project';

const useGetMediumArticles = ({ numberOfArticles }) => {
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    const fetchArticles = async () => {
      let fetchedArticles = await medium.profile.getRecentPosts(railgunProfile);

      if (numberOfArticles) {
        fetchedArticles = fetchedArticles.slice(0, numberOfArticles);
      }

      setArticles(fetchedArticles);
    };

    fetchArticles();
  }, [numberOfArticles]);

  return articles;
};

export default useGetMediumArticles;
