import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const Footer = () => (
  <Box sx={{ margin: 4, textAlign: 'center' }}>
    <Typography gutterBottom>© 2021</Typography>
    <Typography gutterBottom>
      This site only provides relevant information and links for visitors
      interested in RAILGUN.
    </Typography>
    <Typography gutterBottom>
      Please beware of any imposters pretending to be "RAILGUN staff" - this
      site does not offer any products or services for sale.
    </Typography>
  </Box>
);

export default Footer;
