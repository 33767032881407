const faqContent = [
  {
    question: 'What is RAILGUN?',
    answer: `<p>RAILGUN is a smart contract for professional traders and DeFi users that adds privacy protection to cryptocurrency transactions.</p>

      <p>When using the RAILGUN Privacy System, wallet addresses are removed from transactions on open-ledger blockchains. Without RAILGUN, wallet addresses are revealed and recorded on the blockchain. If a wallet address has been recorded in a transaction, anyone can then check that wallet’s history using tools like Etherscan. This is similar to a person having their bank balance and spending history revealed whenever they use a debit card. RAILGUN is the solution to this problem. </p>

      <p>Through zero-knowledge proof (zk-SNARKs) technology, RAILGUN users enjoy full privacy. Their identifying information is kept private when making transfers, trading, using leverage platforms, adding liquidity or using decentralized applications (dApps) any way they like. RAILGUN is compatible with all standard ERC-20 tokens by default.</p>

      <p>The RAILGUN smart contract is already deployed on Ethereum and other blockchains. It can be used by crypto wallets, such as Railway.xyz, or by protocols themselves that want to give their users privacy.</p>`,
  },
  {
    question:
      'How is RAILGUN different from other Privacy-Preserving Protocols?',
    answer: `<p>Other security solutions usually need specialized infrastructure to work. This is called a layer-2 solution. A layer-2 solution cannot directly access DeFi protocols, and relies on bridging technology that has many security vulnerabilities. As layer-2 solutions use their own nodes, they do not have the full security or liquidity of the primary blockchain.</p>

      <p>Other solutions like mixers lack in functionality and ease of use. For example, mixers often do not allow for internal shielded transactions or interactions with smart contracts. They may also require users to send and receive set amounts like 1 or 10 ETH. Mixers do not allow for simple, efficient use of protocols while preserving privacy. They also do not allow users to maintain private balances in their wallets. RAILGUN, however, brings users privacy at rest – meaning user privacy is maintained even when no transactions are taking place. </p>

      <p>The previous generation of privacy protocols cannot compare with the convenience, flexibility, and privacy guaranteed by RAILGUN. For more on how RAILGUN compares with layer-2 projects, see <a href="https://medium.com/@Railgun_Project/what-are-the-drawbacks-of-layer-two-off-chain-blockchain-solutions-22603cf9a707">What are the Drawbacks of Layer-Two (Off-Chain) Blockchain Solutions?</a>.</p>

      <p>for more on how RAILGUN is different from a mixer, see <a href="https://medium.com/@Railgun_Project/how-is-railgun-different-from-a-mixer-36a187d102fe">How is RAILGUN Different from a Mixer?</a>.`,
  },
  {
    question: 'What can I use RAILGUN for?',
    answer: `<p>RAILGUN’s use cases are as numerous as its users are unique. Applications for the Privacy System include:</p>
      <ul>
      <li>Performing darkpool-style trading, ensuring your strategies cannot be copied or counter-traded</li>
      <li>Entering into new coin investments without alerting those who follow your wallet</li>
      <li>Building a shielded balance without outsiders knowing the specifics of your holdings</li>
      <li>Preventing spying and data collection related to transaction habits</li>
      <li>Keeping DeFi habits invisible to advertisers or fraudsters looking to target individuals with particular interests</li>
      <li>Making confidential payments to your attorney when seeking legal advice on personal disputes</li>
      <li>Receiving donations without outsiders being able to view donation history</li>
      <li>Participating in the advancement of cryptographic privacy technology</li>
      <li>Furthering the ideology of bringing the level of privacy possible in the DeFi space to a level equal to that of mainstream financial institutions</li>
      <li>Avoiding awkward situations where acquaintances, employers or even potential dates look into your DeFi holdings to make personal judgements about you</li>
      </ul>

      <p>RAILGUN has as many different use cases as its users can imagine!</p>`,
  },
  {
    question: 'How secure is RAILGUN?',
    answer: `<p>RAILGUN is very secure. The project’s contributors include world-class security experts, blockchain gurus and cryptography experts. The libraries used are well-established. And RAILGUN is very well audited</p>
      <p>Audit companies that have examined RAILGUN’s code include Trail of Bits, ABDK and Zokyo. ABDK are domain experts in cryptography and zero-knowledge proofs. They invented the Poseidon hash system, an essential part of the RAILGUN system.</p>

      <p>Here is ABDK’s audit: <a href="https://ipfs.io/ipfs/QmaV1AAquDzwQmhF2YffKE2vxfehXNBrmpYesKtFf8do2h?filename=Railgun%20ABDK%20Audit%20-%20July%202021.pdf">RAILGUN ABDK Audit - July 2021</a></p>

      <p>RAILGUN’s code is also open-source and can be examined by anyone with enough technical understanding.</p>

      <p>As a layer-one application, RAILGUN also benefits from the security of primary blockchain layers, without the potential vulnerabilities of off-chain solutions. For more on this, see <a href="https://medium.com/@Railgun_Project/what-are-the-drawbacks-of-layer-two-off-chain-blockchain-solutions-22603cf9a707">What Are the Drawbacks of Layer-2 Off-Chain Blockchain Solutions?</a>.</p>`,
  },
  {
    question: 'Is there an admin key, single API, or other central point that RAILGUN needs to run or can control RAILGUN?',
    answer: `<p>The short answer is no – there is no admin key or single API that the RAILGUN protocol depends on.</p>

    <p>The long answer is that there are no admin keys whatsoever – all contracts are owned by the governance contract so only the voting process can change anything. As for APIs, all data needed for the protocol to function is available on-chain. State change data is emitted as events and all transaction validation happens in-contract. Individual frontends may pull data from APIs (such as a quick sync API to speed up initial load or something like coingecko to fetch prices) but none of these are required for the protocol to function.</p>

    <p>As long as a user has access to a frontend and can connect to the Ethereum/Polygon/Binance/etc network they can unstoppably submit transactions. Relayers can be considered as an API if you stretch the definition enough but nothing in the protocol specifies how a relayer should run or who can be a relayer. The current set of relayers use the Waku network to obfuscate IPs and have a certain format they use to validate payment of transaction fees, however anyone can come along and build a relayer that runs on tor/i2p/smoke signals and there isn't anything in place that would prevent them from functioning. Obviously most frontends would try to remain interoperable with the most used systems that are currently in play (RAILWAY + Waku relayers) but the system doesn't lock out other approaches and so preserves decentralization.</p>`
  },
  {
    question: 'What tokens are supported on RAILGUN?',
    answer: `<p>RAILGUN is permissionless. All standard ERC-20 tokens can be used in the RAILGUN Privacy System by default.</p>

      <p>Some ERC-20 tokens are non-standard, like rebasing and lottery tokens. These are currently incompatible with the RAILGUN Privacy System and should not be deposited.</p>

      <p>While it does not fully meet the ERC-20 standard, Tether (USDT) can be used within the RAILGUN Privacy System.</p>`
  },
  {
    question: 'How easy is RAILGUN to use?',
    answer: `<p>RAILGUN is very easy to use through front-ends like web apps. The technology can also easily be integrated into existing protocols.</p>

      <p>While the code is very complex, making use of the RAILGUN Privacy System is easy. Wallet apps that incorporate the RAILGUN smart contract, like <a href="https://railway.xyz">https://railway.xyz</a>, have highly intuitive user interfaces.</p>`
  },
  {
    question: 'What is RAIL? Why should I stake it?',
    answer: `<p>RAIL is the governance token for RAILGUN. It can be staked to gain voting rights on upgrades or changes to RAILGUN. RAIL can be purchased on DeFi exchanges like SushiSwap.</p>

      <p>Stakers gain voting rights as part of the DAO. This empowers users to participate in the future direction of RAILGUN. All key decisions for the protocol are made by the DAO. Any DAO member can also make proposals for changes to the system they would like to see, and submit their proposals for voting.</p>

      <p>The DAO governs rewards for stakers. For example, the DAO can vote to establish weekly/monthly rewards for staked users, pay grants, vote for fee changes, etc. Staked users have also received airdrops for the governance tokens each time RAILGUN has deployed on a new blockchain.</p>

      <p>For more on the RAIL token, see <a href="https://medium.com/@Railgun_Project/rail-tokens-what-are-they-and-what-do-they-do-718b74100a91">this article on RAIL tokens and what they do</a>.</p>

      <p>For more on governance, see the <a href="https://medium.com/@Railgun_Project/railgun-governance-and-staking-guide-eb31a4be3400">Governance and Staking Guide</p>`,
  },
  {
    question: 'Is it illegal to keep my transactions hidden from the public?',
    answer: `<p>Not at all. Keeping your movements to yourself is perfectly legal in all normal countries, and government officials do it as part of normal operations. Privacy is normal.</p>

    <p>If you have a duty to report certain trades or transfers, you may still report your RAILGUN transactions to your employer, local exchange, or local official, and you can share a provably true report to the person who you select. This means your transactions are only hidden from strangers or rivals, and can be visible by your managers or your counterparty, if you so choose.</p>`
  },
  {
    question: 'Why is it that no one can see my private balance, and how does the cryptography work around that?',
    answer: `<p>RAILGUN wallets are made up of two private keys: a spending key and a viewing key. RAILGUN wallet addresses (also known as 0zk addresses) are the two corresponding public keys – spending and viewing.</p>

    <p>So if User A (Alice) knows the RAILGUN address of User B (Bob), she can initiate a transaction.</p>

    <p>Alice does this by creating a note containing what in cryptography is called a commitment. This contains information about the token and the amount to be transferred. These committed values are encrypted using the information in Bob’s public key.</p>

    <p>Next, Alice creates a zk-SNARKs proof, which shows she isn’t spending more than what she has deposited. It also shows that she hasn’t double-spent - also spent her tokens somewhere else. For a simple run-down of what zk-SNARKs is, see <a href="https://medium.com/@Railgun_Project/what-are-zk-snarks-a-simple-guide-221734766cee">here</a>.</p>

    <p>Finally, Alice sends the proof, commitment, and ciphertext to the RAILGUN smart contract. The smart contract verifies the proof, accumulates the commitment and emits the ciphertext as an event. This means it checks everything is correct and makes it possible for Bob to interact with the transaction.</p>

    <p>Only Bob can decrypt the ciphertext using his private viewing key to get the secret information. With the information and his private spending key, Bob can spend this note – meaning that he can transfer or withdraw the tokens.</p>`
  },
  {
    question: 'What is a Zero Knowledge Proof?',
    answer: `<p>In cryptography, a zero-knowledge proof, or zero-knowledge protocol, is when one party can show another they know a value without having to reveal the value itself.</p>

      <p>This is possible through technology such as zk-SNARKs, and the RAILGUN Privacy System uses zero-knowledge proofs to protect users’ privacy and anonymity.</p>

      <p><a href="https://medium.com/@Railgun_Project/what-are-zk-snarks-a-simple-guide-221734766cee">A simple explanation of zk-SNARKs technology</a></p>`,
  },
  {
    question:
      'Can the identity of users making RAILGUN transfers be seen on Etherscan?',
    answer: `<p>As of the full public launch of RAILGUN, the RAILGUN Privacy System is fully anonymous. Wallet addresses and personal information will never be recorded on Etherscan when making transactions from within the RAILGUN Privacy System. This includes when interacting with DeFi and other external protocols. </p>

      <p>The initial transfer of tokens into the RAILGUN system from a public address is still recorded on the blockchain. Only when the user’s balance is Shielded does the smart contract take effect and remove the user’s personal details from subsequent transactions. Similarly, once the user Unshields and sends tokens to a public address, that transaction and the public address are recorded on the blockchain.</p>

      <p>The best way to maintain privacy is to keep tokens within the RAILGUN Privacy System, where no outside observer can see the user’s balance or what they are doing with their tokens.</p>`
  },
  {
    question: 'What is a relayer?',
    answer: `<p>Relayers are intermediaries between RAILGUN users and external addresses, including DeFi protocols.</p>

      <p>Relayers collect transactions and forward them on behalf of users. This removes the link between the individual and the transactions recorded on the blockchain. Even the record of who pays gas fees is made anonymous through the use of relayers.</p>

      <p>RAILGUN-to-RAILGUN internal transactions already have complete privacy without the use of relayers. But relayers are necessary to maintain user privacy when interacting with external protocols like dApps.</p>

      <p>For more, see <a href="https://medium.com/@Railgun_Project/relayers-unleashing-the-full-potential-of-railgun-b24107dc3868">Relayers: Unleashing the Full Potential of RAILGUN</a></p>`,
  },
  {
    question: 'How can we trust relayers? Can they manipulate my transaction?',
    answer: `<p>The RAILGUN Privacy System is zero-trust. This means there is no need to trust relayers, as there is nothing they can do to attack users.</p>

      <p>Any attempt to manipulate transactions will leave contracts invalid. The relayer would gain nothing in the attempt.</p>

      <p>For more, see <a href="https://medium.com/@Railgun_Project/relayers-unleashing-the-full-potential-of-railgun-b24107dc3868">Unleashing the Full Potential of RAILGUN</a></p>`,
  },
  {
    question: "How can a relayer verify that they’re getting paid but not see my balance or the tokens that I'm transacting in the snark proof?",
    answer: `<p>The only note in the SNARK proof that’s encrypted to the relayer’s private key is their relay reward. This means the relayer has no way of knowing what else is in the SNARK proof, but is able to see the reward they will receive.</p>`
  },
  {
    question:
      'Why does there need to be a governance token for every blockchain? Why can’t there just be one ERC20 governance token for all chains?',
    answer: `<p>Having a separate governance token for each blockchain is necessary to keep RAILGUN trustless and fully decentralized. RAILGUN is built with security as well as privacy always in mind. Currently, there is no way to bridge tokens from one chain to another in a fully trustless way without some entity within RAILGUN taking custody of tokens.</p>

      <p>This means the only viable current solution is a governance token for each blockchain.</p>`,
  },
  {
    question:
      'Does using RAILGUN mean my privacy is protected in every possible circumstance?',
    answer: `<p>Once a balance is shielded in the RAILGUN Privacy System, users gain very strong privacy.</p>

      <p>Of course, some specific user behaviors could potentially give strong evidence of their identity, even if the Privacy System does not reveal it directly.</p>

      <p>For example, if a specific balance in a very uncommon token enters the RAILGUN system, and then that same balance in the same token can be seen exiting the RAILGUN system, even if there is no specific identifying information, the likelihood of their being linked would be high.</p>

      <p>Time in the system and changes in token balance would in this case increase the level of anonymity. In such a rare circumstance, caution is advisable.</p>

      <p>See also this article on how private balances are maintained: <a href="https://medium.com/@Railgun_Project/railgun-maintains-private-balances-a1ded076439a">RAILGUN Maintains Private Balances</a></p>`,
  },
  {
    question: 'Are relayers an off-chain solution?',
    answer: `<p>No. Relayers forward transactions and pay gas costs on the behalf of users for a fee. This removes identifying information. The process happens entirely on the primary layer of the blockchain.</p>
    <p>For more on relayers, see here: <a href="https://medium.com/@Railgun_Project/relayers-unleashing-the-full-potential-of-railgun-b24107dc3868">Unleashing the Full Potential of RAILGUN</a></p>`
  },
];

export default faqContent;
