import { Fragment, useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import MenuIcon from '@mui/icons-material/Menu';

import { useHistory } from 'react-router-dom';

import useMediaQuery from '@mui/material/useMediaQuery';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import { RailgunIconButton } from '../components/railgunIconButton';
import { NavDrawer } from '../components/navDrawer';
import {
  GovernanceButton,
  AppButton,
  DocsButton,
} from '../components/appButtons';

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function scrollToAnchor(navigationRefs, anchor) {
  if (!navigationRefs[anchor]?.current) return;
  navigationRefs[anchor].current.scrollIntoView({
    behavior: 'smooth',
    block: 'start',
  });
}

const NavBar = ({ navigationRefs, anchor }) => {
  const history = useHistory();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const useNavDrawer = useMediaQuery('(max-width: 1080px)');

  const openDrawer = () => setIsDrawerOpen(true);

  useEffect(() => {
    scrollToAnchor(navigationRefs, anchor);
  }, [navigationRefs, anchor]);

  const navigateToAnchor = (anchor) => () => {
    if (anchor === 'home') {
      history.push('/');
      scrollToAnchor(navigationRefs, anchor);
    } else {
      history.push(anchor);
    }
    setIsDrawerOpen(false);
  };

  const navigateToHome = navigateToAnchor('home');

  const navAnchors = [
    {
      onClick: navigateToAnchor('about'),
      text: 'About',
    },
    {
      onClick: navigateToAnchor('faq'),
      text: 'FAQ',
    },
    {
      onClick: navigateToAnchor('bugBounty'),
      text: 'Bug Bounty',
    },
    {
      onClick: navigateToAnchor('community'),
      text: 'Community',
    },
    {
      onClick: navigateToAnchor('press'),
      text: 'Press',
    },
  ];

  return (
    <HideOnScroll>
      <AppBar>
        <Toolbar sx={{ justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex' }}>
            {useNavDrawer && (
              <Fragment>
                <IconButton
                  size="large"
                  color="inherit"
                  aria-label="menu"
                  onClick={openDrawer}
                >
                  <MenuIcon />
                </IconButton>

                <NavDrawer
                  anchors={navAnchors}
                  isDrawerOpen={isDrawerOpen}
                  setIsDrawerOpen={setIsDrawerOpen}
                />
              </Fragment>
            )}

            <RailgunIconButton
              sx={{ width: '136px', height: '56px', mr: 1 }}
              onClick={navigateToHome}
            />
            {!useNavDrawer && (
              <Stack direction="row" spacing={2}>
                {navAnchors.map(({ text, onClick }) => (
                  <Button
                    key={text}
                    onClick={onClick}
                    sx={{ whiteSpace: 'nowrap' }}
                  >
                    {text}
                  </Button>
                ))}
              </Stack>
            )}
          </Box>

          {!useNavDrawer && (
            <Stack direction="row" spacing={2}>
              <AppButton sx={{ whiteSpace: 'nowrap' }} />
              <GovernanceButton sx={{ whiteSpace: 'nowrap' }} />
              <DocsButton sx={{ whiteSpace: 'nowrap' }} />
            </Stack>
          )}
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  );
};

export default NavBar;
