import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import Splash from './splash';
import About from './about';
import Faq from './faq';
import Community from './community';
import BugBounty from './bugBounty';

const Home = ({ navigationRefs, setAnchor }) => {
  const { anchor } = useParams();

  useEffect(() => {
    setAnchor(anchor);
  }, [anchor, setAnchor]);

  return (
    <Container>
      <Box
        sx={{
          minHeight: 'calc(100vh - 64px)',
          pt: '64px',
        }}
      >
        <Splash homeRef={navigationRefs.home} />
        <Faq faqRef={navigationRefs.faq} />
        <About aboutRef={navigationRefs.about} />
        <BugBounty bugBountyRef={navigationRefs.bugBounty} />
        <Community communityRef={navigationRefs.community} />
      </Box>
    </Container>
  );
};

export default Home;
