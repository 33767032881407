import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import RailgunIcon from './railgunIcon';

export const RailgunIconSplash = () => (
  <Container maxWidth="sm">
    <Box
      sx={{
        minHeight: '28px',
        height: '66vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <RailgunIcon />
      <Typography sx={{ textAlign: 'center', mt: '25px' }} variant="h5">
        Privacy on Ethereum, BNB Chain and Polygon
      </Typography>
    </Box>
  </Container>
);
