import Button from '@mui/material/Button';

import RailgunIcon from './railgunIcon';

export const RailgunIconButton = (props) => {
  return (
    <Button {...props}>
      <RailgunIcon />
    </Button>
  );
};

export default RailgunIconButton;
