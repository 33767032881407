import { useRef, useState } from 'react';
import { HashRouter, Switch, Route } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';

import theme from '../theme';

import NavBar from './navBar';
import Footer from '../components/footer';
import Home from './home';
import Press from './press';

export default function App() {
  const [anchor, setAnchor] = useState('');
  const home = useRef(null);
  const community = useRef(null);
  const faq = useRef(null);
  const about = useRef(null);
  const articles = useRef(null);
  const bugBounty = useRef(null);

  const navigationRefs = {
    home,
    community,
    faq,
    about,
    articles,
    bugBounty,
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box
        sx={{
          background: 'radial-gradient(ellipse at top, #1e1e21, #141414)',
        }}
      >
        <HashRouter>
          <NavBar navigationRefs={navigationRefs} anchor={anchor} />

          <Switch>
            <Route path="/press/:anchor">
              <Press navigationRefs={navigationRefs} setAnchor={setAnchor} />
            </Route>
            <Route path="/press">
              <Press navigationRefs={navigationRefs} setAnchor={setAnchor} />
            </Route>
            <Route path="/:anchor">
              <Home navigationRefs={navigationRefs} setAnchor={setAnchor} />
            </Route>
            <Route path="/">
              <Home navigationRefs={navigationRefs} setAnchor={setAnchor} />
            </Route>
          </Switch>

          <Footer />
        </HashRouter>
      </Box>
    </ThemeProvider>
  );
}
