export const TrustLinksContent = [
/*
  {
    href: 'https://assets.railgun.org/docs/whitepaper',
    text: 'Whitepaper',
    left: 'Whitepaper [EN / 中文 / 日本 / 한글]',
    right: 'Railgun Project Whitepaper - July 2021.pdf',
  },
*/
  {
    href: 'https://assets.railgun.org/docs/audits/Railgun%20Audit%20Report%20-%20ABDK%20July%202021.pdf',
    text: 'Audit | ABDK',
    left: 'Audit',
    right: 'RAILGUN Audit Report - ABDK July 2021.pdf',
  },
  {
    href: 'https://assets.railgun.org/docs/audits/Railgun%20Audit%20Report%20-%20Zokyo%20November%202021.pdf',
    text: 'Audit | Zokyo',
    left: 'Zokyo Audit',
    right: 'Railgun Audit Report - Zokyo November 2021.pdf',
  },
  {
    href: 'https://www.coingecko.com/en/coins/railgun',
    text: 'RAIL (CoinGecko)',
    left: 'CoinGecko',
    right: 'RAIL (CoinGecko)',
  },
  {
    href: '/#/press',
    text: 'Press',
    left: 'Press',
    right: 'Press',
  },
  /*
  {
    href: 'https://etherscan.io/address/0xe76C6c83af64e4C60245D8C7dE953DF673a7A33D#code',
    text: 'RAIL Token Contract',
    left: 'RAIL Token Contract',
    right: '0xe76C6c83af64e4C60245D8C7dE953DF673a7A33D',
  },
  */
  {
    href: 'https://etherscan.io/address/0xfa7093cdd9ee6932b4eb2c9e1cde7ce00b1fa4b9#code',
    text: 'Contract',
    left: 'Contract',
    right: '0xfa7093cdd9ee6932b4eb2c9e1cde7ce00b1fa4b9#code',
  },
  {
    href: 'https://docs.railgun.org',
    text: 'Docs',
    left: 'Docs',
    right: 'Docs',
  },
];
