import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import FaqEntry from '../../components/faqEntry';
import faqContent from '../../content/faqContent';

const Faq = ({ faqRef }) => (
  <Container ref={faqRef} maxWidth="lg" sx={{ mt: 5 }}>
    <Box>
      <Paper sx={{ padding: 3 }}>
        <Typography variant="h4">FAQ</Typography>
      </Paper>

      {faqContent.map((faqContent, index) => (
        <FaqEntry key={index} {...faqContent} />
      ))}
    </Box>
  </Container>
);
export default Faq;
