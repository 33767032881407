import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { visionContent } from '../../content/visionContent';

const About = ({ aboutRef }) => (
  <Container ref={aboutRef} maxWidth="lg" sx={{ mt: 2 }}>
    <Box>
      <Paper sx={{ padding: 3 }}>
        <Typography variant="h4" gutterBottom>
          {visionContent.title}
        </Typography>
        {visionContent.body.map((text, index) => (
          <Typography key={index} sx={{ mb: 3 }}>
            {text}
          </Typography>
        ))}
      </Paper>
    </Box>
  </Container>
);

export default About;
