import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import parse from 'html-react-parser';

const QuestionHeader = ({ children }) => (
  <AccordionSummary
    expandIcon={<ExpandMoreIcon />}
    sx={{
      '&:hover': {
        backgroundColor: '#ced3df0a',
      },
    }}
  >
    <Typography>{children}</Typography>
  </AccordionSummary>
);

const FaqEntry = ({ question, answer }) => (
  <Accordion>
    <QuestionHeader>{question}</QuestionHeader>
    <AccordionDetails>
      <Typography component="div" variant="body2">
        {parse(answer)}
      </Typography>
    </AccordionDetails>
  </Accordion>
);

export default FaqEntry;
